import React from "react"

import Layout from "../modules/layout"
import BigBanner from "../modules/m18_bigBanner"
import InnerHero from "../modules/m14_InnerHero"
import LeaveData from "../modules/m21_leaveData"
import styled from "styled-components"
import { Box, Text } from "@sqymagma/elements"
import { Link } from "gatsby"
import m21 from "../data/m21B"

const Section = styled(Box)`
  padding: 24px 0px;
`
const Ul = styled.li`
  list-style: square;
  margin: 24px 36px;
`
const Li = styled.li`
  padding: 16px 8px;
  list-style: square;
`

const CookiesPage = () => {
  return (
    <Layout title="" desc="" keywords="">
      <InnerHero bc="Cookies" title="Política de cookies" url="cookies" />
      <Box
        mx="auto"
        width={["224px", "608px", "768px", "940px", "976px"]}
        mt={["24px", "32px", "32px", "64px", "96px"]}
        mb={["48px", "96px", "96px", "128px", "196px"]}
      >
        <Section>
          <Text textStyle="p">
            La presente política de cookies (la “Política de Cookies”) forma parte de la Política de
            Privacidad [INCLUIR HIPERVÍNCULO] y tiene por finalidad informar, de manera clara y
            precisa, a los usuarios (los “Usuarios”) del sitio web alojado bajo el nombre de dominio
            [Añadir nombre del dominio web] y bajo cualquiera de los subdominios del mismo (el
            “Sitio Web”), que son titularidad de la sociedad [Añadir denominación social de la
            sociedad] con CIF número [Añadir número CIF de la sociedad], domicilio social en [Añadir
            domicilio social de la sociedad] e inscrita en el Registro Mercantil de [Añadir ciudad
            del Registro Mercantil donde se encuentre inscrita la sociedad], Tomo [Añadir número de
            tomo], Folio [Añadir número de folio], Hoja [Añadir número de hoja] (en adelante,
            “Living Alternatives”). Siempre que los Usuarios presten su consentimiento, el acceso y
            la navegación por el Sitio Web supondrá el uso y descarga de cookies en los equipos con
            los que estos accedan y naveguen, y se recopilará información de sus visitas y de su uso
            del Sitio Web para garantizar el acceso a determinadas funcionalidades, facilitarle el
            uso y la navegación, así como, en su caso, realizar estadísticas sobre el uso y la
            eficacia del Sitio Web mediante el estudio de los hábitos de navegación de los Usuarios.
          </Text>
        </Section>
        <Section>
          <Section>
            <Text textStyle="h4" my="24px">
              ¿Qué es una cookie?
            </Text>
          </Section>
          <Text textStyle="p">
            Las cookies pueden ser definidas en sentido amplio como dispositivos de uso generalizado
            que se descargan en el terminal del Usuario cuando éste accede o navega por una página
            web (ya sea un ordenador, smartphone o tableta) y que permiten almacenar información que
            puede ser posteriormente actualizada, consultada y recuperada, con diversas finalidades,
            por la entidad responsable de su instalación. Las cookies se utilizan habitualmente para
            medir determinados parámetros de uso de los sitios web tales como el número de
            visitantes, la duración de la visita o su eficacia, así como para facilitar la
            navegación del usuario o guardar y enviar información acerca de sus preferencias y
            acciones pasadas. Como tales, las cookies no están asociados a ninguna información de
            carácter personal. No obstante, también se utilizan para personalizar la experiencia de
            un visitante conocido en un sitio web al asociarse a su información de perfil o
            preferencias de Usuario. Al ofrecer información sobre los hábitos de navegación o las
            acciones de los Usuarios, pueden afectar a la privacidad de los mismos, por ese motivo,
            ofrecemos la información necesaria sobre las cookies utilizadas por el Sitio Web para
            que los Usuarios puedan decidir si consienten su utilización.
          </Text>
        </Section>
        <Section>
          <Section>
            <Text textStyle="h4" my="24px">
              ¿Por qué utilizamos cookies?
            </Text>
          </Section>
          <Text textStyle="p">
            Utilizamos las cookies para obtener más información sobre la forma en que los Usuarios
            utilizan el contenido y navegan por del Sitio Web que ayuden a su mejora en aspectos
            como su usabilidad, la gestión de los espacios publicitarios o para ayudar a desarrollar
            perfiles que permitan mostrar la publicidad en función del mismo o dirigir las acciones
            publicitarias hacia potenciales usuarios de perfil similar. Cuando los Usuarios acceden
            al Sitio Web, se les ofrece información sobre el uso de esta tecnología y se les pide
            que otorguen a Living Alternatives, de manera expresa, su consentimiento para ello. A
            tal efecto, Living Alternatives informa de que ciertas cookies podrían ser necesarias
            para el funcionamiento del Sitio Web y, si el Usuario deniega su consentimiento para su
            activación, el acceso al mismo podría quedar impedido.
          </Text>
        </Section>
        <Section>
          <Section>
            <Text textStyle="h4" my="24px">
              Tipos de cookies
            </Text>
          </Section>
          <Text textStyle="p">
            En este apartado te exponemos los diferentes tipos de cookies, según determinados
            criterios.
            <br /> <br />
            <b> Dependiendo de la finalidad para la que traten la información </b>
            obtenida a través de las mismas, las cookies pueden ser:
          </Text>
          <Ul>
            <Li>
              <Text textStyle="p">
                Cookies técnicas: aquéllas que permiten al Usuario la navegación a través de una
                página web, plataforma o aplicación y la utilización de las diferentes opciones o
                servicios que en ella existan como, por ejemplo, controlar el tráfico y la
                comunicación de datos, identificar la sesión, acceder a partes de acceso
                restringido, recordar los elementos que integran un pedido, realizar el proceso de
                compra de un pedido, realizar la solicitud de inscripción o participación en un
                evento, utilizar elementos de seguridad durante la navegación, almacenar contenidos
                para la difusión de videos o sonido o compartir contenidos a través de redes
                sociales.
              </Text>
            </Li>
            <Li>
              <Text textStyle="p">
                Cookies de personalización: aquéllas que permiten al Usuario acceder al servicio con
                algunas características de carácter general predefinidas en función de una serie de
                criterios en el terminal del Usuario como por ejemplo serian el idioma, el tipo de
                navegador a través del cual accede al servicio, la configuración regional desde
                donde accede al servicio, etc.
              </Text>
            </Li>
            <Li>
              <Text textStyle="p">
                Cookies de análisis: aquéllas que permiten al responsable de las mismas, el
                seguimiento y análisis del comportamiento de los Usuarios de los sitios webs a los
                que están vinculadas. La información recogida mediante este tipo de cookies se
                utiliza en la medición de la actividad de los sitios web, aplicación o plataforma y
                para la elaboración de perfiles de navegación de los Usuarios de dichos sitios,
                aplicaciones y plataformas, con el fin de introducir mejoras en función de análisis
                de los datos de uso que hacen los Usuarios del servicio.
              </Text>
            </Li>
            <Li>
              <Text textStyle="p">
                Cookies publicitarias: aquéllas que permiten la gestión, de la forma más eficaz
                posible, de los espacios publicitarios que, en su caso, el editor haya incluido en
                una página web, aplicación o plataforma desde la que presta el servicio solicitado
                en base a criterios como el contenido editado o la frecuencia en la que se muestran
                los anuncios.
              </Text>
            </Li>
            <Li>
              <Text textStyle="p">
                Cookies de publicidad comportamental: aquéllas que permiten la gestión, de la forma
                más eficaz posible, de los espacios publicitarios que, en su caso, el editor haya
                incluido en una página web, aplicación o plataforma desde la que presta el servicio
                solicitado. Estas cookies almacenan información del comportamiento de los Usuarios
                obtenida a través de la observación continuada de sus hábitos de navegación, lo que
                permite desarrollar un perfil específico para mostrar publicidad en función del
                mismo.
              </Text>
            </Li>
          </Ul>
          <Text textStyle="p">
            Asimismo, <b>según la entidad que las gestione </b>las cookies pueden ser:
          </Text>
          <Ul>
            <Li>
              <Text textStyle="p">
                Propias: aquellas que se envían al equipo terminal del Usuario desde un equipo o
                dominio gestionado por el propio editor y desde el que se presta el servicio
                solicitado por el Usuario.
              </Text>
            </Li>
            <Li>
              <Text textStyle="p">
                De terceros: aquellas que se envían al equipo terminal del Usuario desde un equipo o
                dominio que no es gestionado por el propio editor, sino por otra entidad que trata
                los datos obtenidos a través de las cookies.
              </Text>
            </Li>
          </Ul>
          <b>Según el plazo de tiempo que permanecen activas:</b>
          <Ul>
            <Li>
              <Text textStyle="p">
                De sesión: diseñadas para recabar y almacenar datos mientras el Usuario accede a una
                página web. Expiran una vez el Usuario abandona ese sitio web.{" "}
              </Text>
            </Li>
            <Li>
              <Text textStyle="p">
                Persistentes: aquellas en las que los datos permanecen almacenados en el terminal y
                pueden ser accedidos y tratados durante un periodo de tiempo definido por el
                responsable de la cookie, que puede variar de unos minutos a unos años.{" "}
              </Text>
            </Li>
          </Ul>
        </Section>
        <Section>
          <Section>
            <Text textStyle="h4" my="24px">
              Cookies utilizadas por el Sitio Web
            </Text>
          </Section>
          <Text textStyle="p">
            Siguiendo las directrices de la Agencia Española de Protección de Datos procedemos a
            detallar el uso de cookies que hace el Sitio Web con el fin de informar al Usuario con
            la máxima exactitud posible. Este Sitio Web utiliza las siguientes cookies: COOKIE
            DURACIÓN GESTIÓN FINALIDAD [Nombre cookie] Sesión / Persistentes [CONFIRMAR] Propia / De
            Terceros [CONFIRMAR] Técnica/Personalización/Análisis/Publicidad [CONFIRMAR Y EXPLICAR
            DE FORMA BREVE Y CLARA PARA QUÉ SIRVE] [Nombre cookie] Sesión / Persistente [CONFIRMAR]
            Propia / De Terceros [CONFIRMAR] Técnica/Personalización/Análisis/Publicidad [CONFIRMAR
            Y EXPLICAR DE FORMA BREVE Y CLARA PARA QUÉ SIRVE] [Nombre cookie] Sesión / Persistente
            [CONFIRMAR] Propia / De Terceros [CONFIRMAR] Técnica/Personalización/Análisis/Publicidad
            [CONFIRMAR Y EXPLICAR DE FORMA BREVE Y CLARA PARA QUÉ SIRVE] [Nombre cookie] Sesión /
            Persistente [CONFIRMAR] Propia / De Terceros [CONFIRMAR]
            Técnica/Personalización/Análisis/Publicidad [CONFIRMAR Y EXPLICAR DE FORMA BREVE Y CLARA
            PARA QUÉ SIRVE] [Nombre cookie] Sesión / Persistente [CONFIRMAR] Propia / De Terceros
            [CONFIRMAR] Técnica/Personalización/Análisis/Publicidad [CONFIRMAR Y EXPLICAR DE FORMA
            BREVE Y CLARA PARA QUÉ SIRVE] [Nombre cookie] Sesión / Persistente [CONFIRMAR] Propia /
            De Terceros [CONFIRMAR] Técnica/Personalización/Análisis/Publicidad [CONFIRMAR Y
            EXPLICAR DE FORMA BREVE Y CLARA PARA QUÉ SIRVE] [Nombre cookie] Sesión / Persistente
            [CONFIRMAR] Propia / De Terceros [CONFIRMAR] Técnica/Personalización/Análisis/Publicidad
            [CONFIRMAR Y EXPLICAR DE FORMA BREVE Y CLARA PARA QUÉ SIRVE] Algunos de los terceros que
            recogen datos personales mediante las cookies usadas y descargadas a través del presente
            Sitio Web es posible que, por ejemplo, se encuentren alojados fuera de la Unión Europea
            y del Espacio Económico Europeo o lleven a cabo el almacenamiento, procesamiento o
            tratamiento de cualquier otro tipo respecto de tales datos pasados fuera de la Unión
            Europea y/o del Espacio Económico Europeo. Sin perjuicio de lo anterior, Living
            ALTERNATIVES se preocupa de que, en caso de que se compartan datos, recogidos mediante
            cookies, fuera del Espacio Económico Europeo, las transferencias internacionales
            resultantes queden sujetas a alguna de las garantías adecuadas descritas en el artículo
            46 del Reglamento de Protección de Datos tales como la firma de cláusulas tipo de
            protección de datos o la sujeción de los servicios prestados a un instrumento jurídico
            vinculante como el Privacy Shield. Asimismo, el Usuario podrá informarse de las
            transferencias de datos recabados mediante cookies a terceros países que, en su caso,
            realizan los terceros identificados en la presente política de cookies en sus
            correspondientes políticas: [Añadir hipervínculo a la política de privacidad de las
            entidades que instalan cookies de terceros a través del Sitio Web].
          </Text>
        </Section>
        <Section>
          <Section>
            <Text textStyle="h4" my="24px">
              ¿Cómo desactivar las cookies?
            </Text>
          </Section>
          <Text textStyle="p">
            En cualquier momento, los Usuarios pueden impedir la instalación de cookies en su equipo
            mediante la opción correspondiente de su navegador, pero en dicho caso no podremos
            asegurar el correcto funcionamiento de las distintas funcionalidades de nuestro Sitio
            Web. Generalmente los propios navegadores ofrecen la opción de configurar de forma
            personalizada la gestión de las cookies para cada equipo, así como la posibilidad de
            hacer que su equipo deje de recopilar o almacenar cookies y la manera en que los
            Usuarios pueden eliminar las mismas. En los siguientes enlaces puede encontrar
            información de cómo gestionar las cookies en varios de los navegadores más comúnmente
            utilizados:
            <Ul>
              <Li>
                <Text textStyle="p">
                  Google Chrome:
                  <Link to="https://support.google.com/chrome/answer/95647?hl=es">
                    https://support.google.com/chrome/answer/95647?hl=es
                  </Link>
                </Text>
              </Li>
              <Li>
                <Text textStyle="p">
                  Mozilla Firefox:
                  <Link to="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-web">
                    https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-web
                  </Link>
                </Text>
              </Li>
              <Li>
                <Text textStyle="p">
                  Explorer 10:
                  <Link to="http://windows.microsoft.com/es-es/internet-explorer/delete-manage-cookies#ie=ie-10">
                    http://windows.microsoft.com/es-es/internet-explorer/delete-manage-cookies#ie=ie-10
                  </Link>
                </Text>
              </Li>
              <Li>
                <Text textStyle="p">
                  Internet Explorer 11:
                  <Link to="http://windows.microsoft.com/es-es/internet-explorer/delete-manage-cookies#ie=ie-11">
                    http://windows.microsoft.com/es-es/internet-explorer/delete-manage-cookies#ie=ie-11
                  </Link>
                </Text>
              </Li>
              <Li>
                <Text textStyle="p">
                  Safari:
                  <Link to="https://support.apple.com/kb/PH19214?viewlocale=es_ES&locale=es_ES">
                    https://support.apple.com/kb/PH19214?viewlocale=es_ES&locale=es_ES
                  </Link>
                </Text>
              </Li>
              <Li>
                <Text textStyle="p">
                  Edge:
                  <Link to="http://windows.microsoft.com/es-es/windows-10/edge-privacy-faq">
                    http://windows.microsoft.com/es-es/windows-10/edge-privacy-faq
                  </Link>
                </Text>
              </Li>
            </Ul>
          </Text>
        </Section>
        <Section>
          <Section>
            <Text textStyle="h4" my="24px">
              Modificación de la presente Política de Cookies
            </Text>
          </Section>
          <Text textStyle="p">
            Es posible que actualicemos la política de cookies del Sitio Web debido a, entre otros
            motivos, exigencias de la normativa aplicable en cada momento, motivos técnicos o
            cambios en los servicios ofrecidos por Living Alternatives. Por ello, se recomienda a
            los Usuarios revisar esta política cada vez que accedas al Sitio Web con el objetivo de
            estar adecuadamente informado sobre cómo y para qué utiliza Living Alternatives las
            cookies y otras tecnologías.
          </Text>
        </Section>
      </Box>
      <LeaveData data={m21} />
      <BigBanner cta="Ver casos de éxito">
        100% de éxito en el cambio de uso de locales comerciales a vivienda
      </BigBanner>
    </Layout>
  )
}

export default CookiesPage
